export const PROVINCES = {
  A: "Province of Alicante",
  AB: "Province of Albacete",
  AL: "Province of Almería",
  AV: "Province of Ávila",
  B: "Province of Barcelona",
  BA: "Province of Badajoz",
  BI: "Bilbao",
  BU: "Province of Burgos",
  C: "Province of A Coruña",
  CA: "Province of Cádiz",
  CC: "Province of Cáceres",
  CE: "Ceuta",
  CO: "Province of Córdoba",
  CR: "Province of Ciudad Real",
  CS: "Castelló",
  CU: "Province of Cuenca",
  FP: "Bioko",
  GC: "Province of Las Palmas",
  GE: "Gerona (Girona)",
  GI: "Province of Girona",
  GR: "Province of Granada",
  GU: "Province of Guadalajara",
  H: "Province of Huelva",
  HU: "Province of Huesca",
  IF: "Ifni",
  J: "Province of Jaén",
  L: "Province of Lleida",
  LE: "Province of León",
  LO: "Logroño",
  LR: "La Rioja",
  LU: "Province of Lugo",
  M: "Community of Madrid",
  MA: "Province of Málaga",
  ML: "Melilla",
  MU: "Region of Murcia",
  NA: "Navarre",
  O: "Oviedo",
  OU: "Province of Ourense",
  P: "Province of Palencia",
  PM: "Palma, Majorca",
  PO: "Province of Pontevedra",
  RM: "Rio Muni",
  S: "Santander",
  SA: "Province of Salamanca",
  SE: "Province of Seville",
  SG: "Province of Segovia",
  SO: "Province of Soria",
  SS: "Donostia",
  T: "Province of Tarragona",
  TE: "Province of Teruel",
  TF: "Province of Santa Cruz de Tenerife",
  TO: "Province of Toledo",
  V: "Province of Valencia",
  VA: "Province of Valladolid",
  VI: "Vitoria",
  Z: "Province of Zaragoza",
  ZA: "Province of Zamora"
};
